<!-- 安检周期配置 -->
<template>
  <div class="content">
    <div class="all" style=''>
      <p class="detil-code">超时提醒配置</p>
      <div class="table">
        <!--数据表格-->
        <el-table
          ref="tableData"
          :data="tableData"
          style="width: 100%;"
          max-height="400"
          border
          :stripe="true"
          :header-cell-style="{color:'#333',fontFamily:'MicrosoftYaHeiUI',fontSize:'14px',fontWeight:900,background:'#f8f8f9'}">
          <el-table-column prop="priorLevel" label="优先级" align="left" show-overflow-tooltip>
            <template slot-scope="{ row }">
              {{row.priorLevel == '特紧急' ? row.priorLevel+'/投诉工单' : row.priorLevel}}
            </template>
          </el-table-column>
          <el-table-column prop="timeoutDay" label="超时提醒天数（单位：天）" align="left" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <el-input v-model.number="row.timeoutDay" 
              type="number"
              placeholder="请输入超时提醒天数"  
              @input="row.timeoutDay= row.timeoutDay>9999  ? 9999 : (( row.timeoutDay< 0) ? 0: row.timeoutDay)"
              @blur="sortNumber(row)"></el-input>
            </template>
          </el-table-column>
        </el-table>
      </div>
      
    </div>
  </div>
</template>
<script>
import { getTimeoutList,updateOrders} from "@/RequestPort/serviceOrders";
export default {
  name: 'Cycle',
  data() {
    return {
      tableData: [], //列表对象
    }
  },
  mounted() {
    // 获取超时配置列表
    this.getListTypes()
  },
  methods: {
    /**
     * 获取超时配置列表
     */
    getListTypes(){
      getTimeoutList().then(res => {
        if(res.code == 200) {
          this.tableData = res.data
        }
      })
    },
    /**
     * 更改提醒日期
     */
    sortNumber(row){
      let data = {
        id:row.id,
        timeoutDay:row.timeoutDay
      }
      if(typeof(row.timeoutDay) != 'number') {
        this.$message.error('请输入数字');
        return
      }
      updateOrders(data).then(res => {
        this.$message.success(res.data);
        // 刷新列表
        this.getListTypes()
      })
    },
  }
}
</script>

<style scoped lang='less'>
// 小标题
.detil-code {
  padding-left: 10px;
  padding-bottom:10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
}
// 小标题前的竖杠
.detil-code:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007aff;
  top: 0;
  left: 0;
}
.table {
  padding: 0!important;
  position: relative;
  // border: 1px solid #ecedf1;
  // border-radius: 5px;
  // margin-right: 1.5%;
}
.all {
  background-color: white;
  height:calc(100vh - 243px);
  overflow: auto;
  // padding-top: 0.5%;
}
.content {
  background: #ffffff;
  width: 100%;
  border-radius: 0px!important;
  padding-top: 20px;
}

</style>
