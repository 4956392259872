
import axios from '@/common/js/request'

/**********************客服派单超时提醒设置**************************** */
// 获取超时配置列表
export function getTimeoutList(params){  
    const getTimeoutList = axios({
        url: '/dispatch/timeout/getTimeoutList',
        method: 'get',
        params
    })
    return getTimeoutList
}
// 更新超时信息
export function updateOrders(data){  
    const updateOrders = axios({
        url: '/dispatch/timeout/update',
        method: 'post',
        data
    })
    return updateOrders
}

/************************采集项******************************* */
// 采集项--分页列表
export function getitemPage(params){  
    const getitemPage = axios({
        url: '/dispatch/collectItem/itemPage',
        method: 'get',
        params
    })
    return getitemPage
}
// 采集项--新增或修改
export function itemInsertOrUpdate(data){  
    const itemInsertOrUpdate = axios({
        url: '/dispatch/collectItem/itemInsertOrUpdate',
        method: 'post',
        data
    })
    return itemInsertOrUpdate
}
// 采集项--分页列表
export function itemDetails(params){  
    const itemDetails = axios({
        url: '/dispatch/collectItem/itemDetails',
        method: 'get',
        params
    })
    return itemDetails
}
// 采集项--排序
export function updateSortById(params){  
    const updateSortById = axios({
        url: '/dispatch/collectItem/updateSortById',
        method: 'get',
        params
    })
    return updateSortById
}
// 采集项--编辑详情1
export function itemDetails1(params){  
    const itemDetails1 = axios({
        url: '/dispatch/collectItem/itemDetails1',
        method: 'get',
        params
    })
    return itemDetails1
}
// 采集项--启用停用
export function itemGeneralStatusToggle(params){  
    const itemGeneralStatusToggle = axios({
        url: '/dispatch/collectItem/itemGeneralStatusToggle',
        method: 'get',
        params
    })
    return itemGeneralStatusToggle
}
/***********************采集项模板************************* */
// 采集模板分页列表
export function templatePageList(params){  
    const templatePage = axios({
        url: '/dispatch/collectTemplate/templatePage',
        method: 'get',
        params
    })
    return templatePage
}
// 采集模板新增或修改
export function templateInsertOrUpdate(data){  
    const templateInsertOrUpdate = axios({
        url: '/dispatch/collectTemplate/templateInsertOrUpdate',
        method: 'post',
        data
    })
    return templateInsertOrUpdate
}
// 采集项--列表
export function templateItemList(data){  
    const templateItemList = axios({
        url: '/dispatch/collectItem/itemList',
        method: 'post',
        data
    })
    return templateItemList
}
// 采集模板查看详情
export function templateDetails(params){  
    const templateDetails = axios({
        url: '/dispatch/collectTemplate/templateDetails',
        method: 'get',
        params
    })
    return templateDetails
}
// 采集模板启用停用
export function templateGeneralStatusToggle(params){  
    const templateGeneralStatusToggle = axios({
        url: '/dispatch/collectTemplate/templateGeneralStatusToggle',
        method: 'get',
        params
    })
    return templateGeneralStatusToggle
}
// 查询字典树(可批量查询)
export function findDictTreeByCode(params){  
    const findDictTreeByCode = axios({
        url: '/dict/findDictTreeByCode',
        method: 'get',
        params
    })
    return findDictTreeByCode
}
/******************************咨询工单****************************************** */
// 分页列表
export function consultOrderPage(params){  
    const consultOrderPage = axios({
        url: '/dispatch/repConsult/orderPage',
        method: 'get',
        params
    })
    return consultOrderPage
}
// 居民用户
export function userPageList(params){  
    const userPageList = axios({
        url: '/arumArchive/userPageList',
        method: 'get',
        params
    })
    return userPageList
}
// 非居民用户
export function unUserPageList(params){  
    const unUserPageList = axios({
        url: '/AaumArchive/userPageList',
        method: 'get',
        params
    })
    return unUserPageList
}
// 新增工单
export function ZXsaveOrder(data){  
    const ZXsaveOrder = axios({
        url: '/dispatch/repConsult/saveOrder',
        method: 'post',
        data
    })
    return ZXsaveOrder
}
// 更新工单
export function ZXupdateOrder(data){  
    const updateOrder = axios({
        url: '/dispatch/repConsult/updateOrder',
        method: 'post',
        data
    })
    return updateOrder
}
// 获取详情
export function ZXorderDetails(params){  
    const orderDetails = axios({
        url: '/dispatch/repConsult/orderDetails',
        method: 'get',
        params
    })
    return orderDetails
}
// 解决
export function solve(data){  
    const solve = axios({
        url: '/dispatch/repConsult/solve',
        method: 'post',
        data
    })
    return solve
}
/*************************投诉工单******************************** */
// 分页列表
export function TSorderPage(params){  
    const TSorderPage = axios({
        url: '/dispatch/repComplain/orderPage',
        method: 'get',
        params
    })
    return TSorderPage
}
// 新增工单
export function TSsaveOrder(data){  
    const TSsaveOrder = axios({
        url: '/dispatch/repComplain/saveOrder',
        method: 'post',
        data
    })
    return TSsaveOrder
}
// 更新工单
export function TSupdateOrder(data){  
    const TSupdateOrder = axios({
        url: '/dispatch/repComplain/updateOrder',
        method: 'post',
        data
    })
    return TSupdateOrder
}
// 解决
export function TSsolve(data){  
    const TSsolve = axios({
        url: '/dispatch/repComplain/solve',
        method: 'post',
        data
    })
    return TSsolve
}
// 获取详情
export function TSorderDetails(params){  
    const TSorderDetails = axios({
        url: '/dispatch/repComplain/orderDetails',
        method: 'get',
        params
    })
    return TSorderDetails
}
/******************************业务工单************************************ */
// 新增工单
export function businessSaveOrder(data){  
    const saveOrder = axios({
        url: '/dispatch/business/saveOrder',
        method: 'post',
        data
    })
    return saveOrder
}
// 更新工单
export function YWupdateOrder(data){  
    const YWupdateOrder = axios({
        url: '/dispatch/business/updateOrder',
        method: 'post',
        data
    })
    return YWupdateOrder
}
// 分页列表
export function YWorderPage(params){  
    const YWorderPage = axios({
        url: '/dispatch/business/orderPage',
        method: 'get',
        params
    })
    return YWorderPage
}
// 获取详情
export function YWorderDetails(params){  
    const YWorderDetails = axios({
        url: '/dispatch/business/orderDetails',
        method: 'get',
        params
    })
    return YWorderDetails
}
// 转派
export function YWtransfer(params){  
    const YWtransfer = axios({
        url: '/dispatch/business/transfer',
        method: 'get',
        params
    })
    return YWtransfer
}