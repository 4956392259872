<template>
  <div class="tabs">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="字典配置" name="first">
        <Dict v-if="activeName=='first'"></Dict>
      </el-tab-pane>
      <el-tab-pane label="超时提醒配置" name="second">
        <Cycle v-if="activeName=='second'"></Cycle>
      </el-tab-pane>
      <el-tab-pane label="采集项配置" name="third">
        <collec v-if="activeName=='third'"></collec>
      </el-tab-pane>
      <el-tab-pane label="采集模板配置" name="fourth">
         <sample v-if="activeName=='fourth'"></sample>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import Dict from './dict.vue'
import Cycle from './cycle.vue'
import collec from './collec.vue'
import sample from './sample.vue'
export default {
  name: 'Index',
  components: { Dict , Cycle , collec , sample  },
  data() {
    return {
      activeName: 'first',
      options:[],
      value:''
    }
  },
  mounted() {
    if(this.$route.query.first == 1) {
      this.activeName = 'third'
    }
    else if(this.$route.query.first == 2) {
      this.activeName = 'fourth'
    }
    else if(this.$route.query.first) {
      this.activeName = this.$route.query.first
    }
  },
  methods: {
    handleClick(tab, event) {
      this.$router.push({ path: '/home/dicManage', query: { first: this.activeName } })
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  background: #ffffff;
  width: 100%;
  border-radius: 6px;
  // padding: 10px;
}
.tabs{
  margin: 10px;
  background: #ffffff;
  border-radius: 6px;
  padding:0 20px 10px;
}
/deep/.el-tabs__header{
  margin-bottom: 00px;
}
</style>
