<template>
  <div>
    <div class="content">
      <div class="all">
      <div class="SearchBar">
        <el-form :inline="true" class="searchBoxForm">
          <el-form-item label="所属类型" class="searchItem">
            <!-- <el-select v-model="pageParams.userType" placeholder="请选择" size="mini" clearable @change="searchList()">
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select> -->
            <el-cascader
                  size="mini"
                  multiple
                  style="width: 100%"
                  :options="DeviceTypeArray"
                  v-model="equipmentTypees"
                  :props="{ label: 'name', value: 'code',emitPath:false,multiple: true }"
                  separator=">"
                  clearable
                  collapse-tags
                  @change="deformation">
                </el-cascader>
          </el-form-item>
          <el-form-item label="使用情况" class="searchItem">
            <el-select v-model="pageParams.used" placeholder="请选择" size="mini" clearable @change="searchList()">
              <el-option
                v-for="item in useCondition"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="启用状态" class="searchItem">
            <el-select v-model="pageParams.generalStatus" placeholder="请选择" size="mini" clearable @change="searchList()">
              <el-option
                v-for="item in enableStart"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="模板名称" class="searchItem">
            <el-input
              size="mini"
              v-model="pageParams.tplName"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="" class="searchItem">
             <el-button type="primary" @click="searchList()" size="mini" >查询</el-button>
             <el-button type="warning" @click="searchList(1)" size="mini" >重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="contain">
      <!-- <div class="table-height"> -->
      <div class="tableTool">
        <span > 
          <el-button
                type="primary"
                plain
                 size="mini"
                v-if="$anthButtons.getButtonAuth('addkefutemplate')"
                 @click="goPath('/home/serviceOrder/options/addSample')">添加</el-button>
        </span>
      </div>
      <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%;margin: 10px 0 15px;"
            border
            class="table-height-in"
            :stripe="true"
            height="calc(100vh - 380px)"
            :header-cell-style="{
              color: '#333',
              fontFamily: 'MicrosoftYaHeiUI',
              fontSize: '14px',
              fontWeight: 900,
              textAlign:'center',
              background: '#f8f8f9',
            }"
          >
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          align="left"
          width="50"
        >
        </el-table-column>
        <el-table-column
            prop="tplName"
            label="模板名称"
            align="left"
            show-overflow-tooltip
          >
        </el-table-column>
        <el-table-column
            prop="repairTypeName"
            label="分类"
            align="left"
            show-overflow-tooltip
          >
        </el-table-column>
        <el-table-column
            prop="itemCount"
            label="采集项数量"
            align="left"
            show-overflow-tooltip
          >
        </el-table-column>
        <el-table-column
            prop="usedName"
            label="使用情况"
            align="left"
            show-overflow-tooltip
          >
        </el-table-column>
        <el-table-column prop="generalStatusName" label="启用状态" align="left">
          <template slot-scope="{row}">
              <el-tag v-if="row.generalStatusName== '已停用'" type="danger" size="mini">{{row.generalStatusName}}</el-tag>
              <el-tag v-else type="success" size="mini">{{row.generalStatusName}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="left" width="250">
          <template slot-scope="{row}">
            <div class="operateTool">
              <el-button type="primary" @click="goPath('/home/serviceOrder/options/sampleDesc',row.id)"
               size="mini">查看</el-button>
              <el-button type="primary" 
              v-if="row.usedName == '未使用' && row.generalStatus== 0 && $anthButtons.getButtonAuth('updatatemplate')"  
              size="mini" @click="goPath('/home/serviceOrder/options/addSample',row.id)">编辑</el-button>
              <el-button type="success" v-if="row.generalStatus== 0 && $anthButtons.getButtonAuth('uptemplate')" 
              @click="stateUpdata(row.id)" size="mini">启用</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    <!-- </div> -->
      <el-pagination
        background
        layout="total, prev, pager, next,sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageParams.current"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageParams.size"
      >
      </el-pagination>
    </div>
    </div>
  </div>
</template>

<script>
import { templatePageList,templateGeneralStatusToggle,findDictTreeByCode} from "@/RequestPort/serviceOrders";
import pageMixins from "@/utils/pageMixins";
export default {
  name:'Sample',
  mixins: [pageMixins],
  data() {
    return {
      pageParams: {
        current: 1,
        size: 100,
      }, //  分页查询参数
      tableData: [], //  表格数据
       total:0,
      title:'',
      dialogVisible:false,//启用弹框
      value:'',
      // 用户分类
      options:[],
      // 使用情况
      useCondition:[
        {label:'未使用',value:1},
        {label:'被使用',value:2},
      ],
      // 启用状态
      enableStart:[
        {label:'已停用',value:0},
        {label:'已启用',value:1},
      ],
      DeviceTypeArray:[],
      equipmentTypees:[]
    };
  },
  mounted() {
    this.getList(1)
    let that = this
    // 获取分类字典
    findDictTreeByCode({dictCode:'ywgd,GAS_PATROL_EVENT',excludeCode:'yichangbaoxiu1'}).then(res => {
      this.DeviceTypeArray = res.data
    })
  },
  methods: {
    deformation(val){
      // this.pageParams.repairType = val
      let text = []
      this.equipmentTypees.forEach(el => {
        text.push(el)
      })
      this.pageParams.repairType = text.join(',')
      // this.getList(1)
    },
    /**
     * 获取列表
     */
    getList(val){
      if(val){
        this.pageParams.current = 1
      }
      templatePageList(this.pageParams).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    /**
     * 更改状态
     */
    stateUpdata(val){
      let that = this
      this.$confirm('是否启用此模板，启用后将自动停用该类型其他模板', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal:false,
          type: 'warning'
        }).then(() => {
          templateGeneralStatusToggle({id:val}).then(res => {
            that.$message({
              type: 'success',
              message: res.data
            });
            that.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
    /**
     * 搜索
     */
    searchList(val){
      
      if(val){
        this.equipmentTypees=[]
        this.pageParams ={
          current:1,
          size: 100,
        }
      } else {
        this.pageParams.current = 1
      }
      this.getList()
    },
    handleSizeChange(val) {
      this.pageParams.size = val
      this.pageParams.current = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageParams.current = val
      this.getList()
    },
    //跳转
    goPath(path, id, disable) {
      this.$router.push({ path: path, query: { id: id, disable: disable } })
    },
  },
};
</script>

<style lang="scss" scoped>
.searchBox {
  .searchBoxForm {
    display: flex;
    align-items: center;
  }
}
// .tableTool{    height: 25px;}
 .searchItem {
      margin-bottom: 1%;
      //  margin-left: 1%;
    }
// .info-item {
//   margin-bottom: 20px;
// }
.label {
  color: #909399;
}
.content-item {
  color: #444444;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  // margin: 10px;
  // padding-top: 1%;

  background-color: #ffffff;
}
.contain{
  margin:0;
  padding:0;
}

.all {
  // background-color:#f3f4f8;
  height:auto
}
.content {
  background: #ffffff;
  width: 100%;
  padding-top: 5px;
  // border-radius: 6px;
  overflow:auto;
  // height: calc(100vh - 245px);
}
.content-text {
  display: inline-block;
  width: 200px;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.diaBtn{
  width: 100px;
  height: 40px;
  margin-bottom: 15px;
}
</style>