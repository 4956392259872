<template>
  <div>
    <div class="content">
      <div class="all">
      <div class="SearchBar">
        <el-form :inline="true" class="searchBoxForm">
          <el-form-item label="采集名称" class="searchItem">
            <el-input
              size="mini"
              v-model="pageParams.itemName"
              placeholder="请输入"
              clearable
              class="search"
            ></el-input>
          </el-form-item>
          <el-form-item label="录入类型" class="searchItem">
            <el-select v-model="pageParams.inputType" placeholder="请选择" size="mini" clearable class="search" @change="searchList">
              <el-option
                v-for="item in admission"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="必填项" class="searchItem">
            <el-select v-model="pageParams.requiredFields" placeholder="请选择" size="mini" clearable class="search" @change="searchList">
              <el-option
                v-for="item in requiredList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="使用情况" class="searchItem">
            <el-select v-model="pageParams.used" placeholder="请选择" size="mini" clearable class="search" @change="searchList">
              <el-option
                v-for="item in useCondition"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="启用状态" class="searchItem">
            <el-select v-model="pageParams.generalStatus" placeholder="请选择" size="mini" clearable class="search" @change="searchList">
              <el-option
                v-for="item in enableStart"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" class="searchItem">
             <el-button type="primary" @click="searchList(1)" size="mini" >查询</el-button>
             <el-button type="warning" @click="closeList(1)" size="mini" >重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="contain">
      <div class="tableTool">
        <span> 
          <el-button type="primary"
                plain size="mini" 
                v-if="$anthButtons.getButtonAuth('addegather')"
                @click="goPath('/home/serviceOrder/options/addCollec')">添加</el-button>
        </span>
      </div>
      <div >
        <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%;margin: 12px 0px 15px;"
            border
            height="calc(100vh - 378px)"
            :stripe="true"
            :header-cell-style="{
              color: '#333',
              fontFamily: 'MicrosoftYaHeiUI',
              fontSize: '14px',
              fontWeight: 900,
              textAlign:'center',
              background: '#f8f8f9',
            }"
          >
          <el-table-column
            type="index"
            :index="indexMethod"
            label="序号"
            align="left"
            width="50"
          >
          </el-table-column>
          <el-table-column
            prop="itemName"
            label="采集名称"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="inputTypeName"
            label="录入类型"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="requiredFieldsName"
            label="必填项"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="sortNum"
            label="排序"
            align="left"
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">
              <el-input v-model.number="row.sortNum" 
              placeholder="请输入排序"  
              type="number"
                        @input="row.sortNum = row.sortNum>9999  ? 9999 : (( row.sortNum< 0) ? parseInt(Math.abs(row.sortNum)): parseInt(row.sortNum))"
              @blur="sortNumber(row)"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="usedName"
            label="使用情况"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column prop="generalStatusName" label="启用状态" align="left">
          </el-table-column>
          <el-table-column label="操作" align="left" width="230">
            <template slot-scope="scope">
              <div class="operateTool">
                <el-button type="primary" @click="goPath('/home/serviceOrder/options/collecDesc',scope.row.id)"
                 size="mini">查看</el-button>
                <el-button type="primary" 
                v-if="scope.row.usedName == '未使用' && scope.row.generalStatus == 0  && $anthButtons.getButtonAuth('updategather')"
                @click="goPath('/home/serviceOrder/options/addCollec',scope.row.id)"
                 size="mini">编辑</el-button>
                <el-button type="success" 
                @click="onShowDesign(scope.row)" 
                size="mini" 
                v-if="scope.row.generalStatus == 0  && $anthButtons.getButtonAuth('upgather')"
                style="background:#3DAF40">启用</el-button>
                <el-button type="danger" 
                @click="onShowDesign(scope.row)" 
                size="mini" 
                v-if="scope.row.generalStatus == 1  && $anthButtons.getButtonAuth('blockgather')"
                style="background:red">停用</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      
    </div>
    <el-pagination
        background
        layout="total, prev, pager, next,sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageParams.current"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageParams.size"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getitemPage,updateSortById,itemGeneralStatusToggle} from "@/RequestPort/serviceOrders";
import pageMixins from "@/utils/pageMixins";
export default {
  name:'Collec',
  mixins: [pageMixins],
  data() {
    return {
      pageParams: {
        current: 1,
        size: 100,
      }, //  分页查询参数
      total:0,
      tableData: [{}], //  表格数据
      title:'',
      value:'',
      options:[],
      // 录入类型
      admission:[
        {label:'文本框',value:1},
        {label:'单选',value:2},
        {label:'多选',value:3},
        {label:'上传照片',value:4},
        {label:'数字框',value:5},
      ],
      // 必填项
      requiredList:[
        {label:'必填',value:1},
        {label:'非必填',value:2},
      ],
      // 使用情况
      useCondition:[
        {label:'未使用',value:1},
        {label:'被使用',value:2},
      ],
      // 启用状态
      enableStart:[
        {label:'已停用',value:0},
        {label:'已启用',value:1},
      ]
    };
  },
  mounted() {
    this.getList()

  },
  methods: {
    //查列表
    getList(val){
      if(val){
        this.pageParams.current = 1
      }
      getitemPage(this.pageParams).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    /**
     * 搜索
     */
    searchList(){
      this.pageParams.current = 1
      this.getList()
    },
    /**
     * 重置
     */
    closeList(){
      this.pageParams=  {
        current: 1,
        size: 100,
      },
      this.getList()
    },
    //跳转
    goPath(path, id, disable) {
      this.$router.push({ path: path, query: { id: id, disable: disable } })
    },
    /**
     * 启用/停用
     */
    onShowDesign(row) {
      itemGeneralStatusToggle({id:row.id}).then(res => {
        this.$message.success(res.data);
        this.getList(1)
      })
    },
    /**
     * 排序
     */
    sortNumber(row){
      let data ={
        id:row.id,
        sortNum:row.sortNum
      }
      console.log(data)
      updateSortById(data).then(res => {
        this.$message.success(res.data);
        // 刷新列表
        this.getList(1)
      })
    },
    handleSizeChange(val) {
      this.pageParams.size = val
      this.pageParams.current = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageParams.current = val
      this.getList()
    }
  },
};
</script>

<style lang="scss" scoped>
.searchBox {
  .searchBoxForm {
    display: flex;
    align-items: center;
  }
}
// .tableTool{    
//   height: 25px;
// }
.searchItem {
  margin-bottom: 18px;
  // margin-left: 1%;
}
.label {
  color: #909399;
}
.content-item {
  color: #444444;
}
// .SearchBar {
  // border-radius: 5px;
  // margin: 10px;
  // padding-top: 1%;
  // background-color: #ffffff;
// }
.all {
  // background-color:#f3f4f8;
  height:auto
}
.content {
  background: #ffffff;
  width: 100%;
  padding-top: 5px;
  border-radius: 6px;
  overflow:auto;
  // height: calc(100vh - 248px);
}
.search{
  width: 190px;
}
.contain{
  margin: 0;
  padding: 0;
}
.el-form-item{
  margin-left: 0;
}
.el-pagination{
      margin: 15px 0 5px;
}
</style>